import { styled } from '@mui/material'

import { ThumbnailPicture, Typography } from '@components/ui/atoms'

export const StyledCard = styled('div')`
  display: flex;
  border-radius: 8px;
  width: 100%;
  padding: 12px;
  margin-top: 8px;
  background: var(--Martinique);
`

export const StyledThumbnailPicture = styled(ThumbnailPicture)`
  max-width: 165px;
  object-fit: cover;
  border-radius: 6px;
`

export const StyledInfoLabel = styled(Typography)`
  color: var(--Neutral1);
  background-color: var(--SecondaryVariant);
  padding: 8px 15px;
  border-radius: 8px;
  backdrop-filter: blur(34px);
  max-width: 100px;
  text-align: center;
  text-overflow: ellipsis;
`
