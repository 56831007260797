import {
  css,
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  styled,
  Typography,
  type CardMediaProps,
  type CardContentProps,
  type TypographyProps
} from '@mui/material'

import { IButtonProps } from '@components/ui/atoms/Button/Button.types'
import { Button } from '@components/ui/atoms'

export const StyledCardMediaContainer = styled('div')`
  position: relative;
`

export const StyledCard = styled(Card)`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px 8px 8px 8px;
  background: var(--Neutral8);
`

export const StyledSoldOutTypography = styled(Typography, {
  shouldForwardProp: propName => propName !== 'layoutMode'
})<
  {
    layoutMode?: 'responsive' | 'fixed'
  } & TypographyProps
>`
  color: var(--Neutral10);

  ${({ layoutMode = 'fixed' }) => {
    if (layoutMode === 'fixed') {
      return css`
        font-size: 14px !important;
        line-height: 18px !important;
      `
    }
  }}
`

export const StyledCompetitionNameTypography = styled(Typography, {
  shouldForwardProp: propName => propName !== 'layoutMode'
})<
  {
    layoutMode?: 'responsive' | 'fixed'
  } & TypographyProps
>`
  color: var(--Neutral1);

  ${({ layoutMode = 'fixed' }) => {
    if (layoutMode === 'fixed') {
      return css`
        font-size: 18px !important;
        line-height: 24px !important;
      `
    }
  }}
`
export const StyledClosedEventTypography = styled(Typography, {
  shouldForwardProp: propName => propName !== 'layoutMode'
})<
  {
    layoutMode?: 'responsive' | 'fixed'
  } & TypographyProps
>`
  color: var(--Neutral1);

  ${({ layoutMode = 'fixed' }) => {
    if (layoutMode === 'fixed') {
      return css`
        font-size: 48px !important;
        line-height: 40px !important;
      `
    }
  }}
`

export const StyledCardContent = styled(CardContent, {
  shouldForwardProp: propName => propName !== 'layoutMode'
})<
  {
    layoutMode?: 'responsive' | 'fixed'
  } & CardContentProps
>`
  height: 100%;

  ${({ layoutMode = 'fixed' }) => {
    if (layoutMode === 'fixed') {
      return css`
        padding-bottom: 16px !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
      `
    }

    if (layoutMode === 'responsive') {
      return css`
        padding-bottom: 8px !important;
        padding-left: 8px !important;
        padding-right: 8px !important;

        @media (min-width: 600px) {
          padding-bottom: 16px !important;
          padding-left: 16px !important;
          padding-right: 16px !important;
        }
      `
    }
  }}
`

export const StyledCardContentStack = styled(Stack)`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const StyledCardMedia = styled(CardMedia, {
  shouldForwardProp: propName => propName !== 'isPast'
})<
  {
    isPast?: boolean
  } & CardMediaProps<'img'>
>`
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;

  img {
    width: 100%;
    height: 100%;
  }

  ${({ isPast = false }) => {
    if (isPast) {
      return css`
        filter: contrast(0.2);
      `
    }
  }}
`

export const StyledEnterButton = styled(Button, {
  shouldForwardProp: propName => propName !== 'layoutMode'
})<
  {
    layoutMode?: 'responsive' | 'fixed'
  } & IButtonProps
>`
  ${({ layoutMode = 'responsive' }) => {
    if (layoutMode === 'responsive') {
      return css`
        border-radius: 4px;
        padding: 3px 16px;

        @media (min-width: 600px) {
          border-radius: 6px;
          padding: 6px 16px;
        }
      `
    }
  }}
`

export const StyledBadgeContainer = styled(Box, {
  shouldForwardProp: propName => propName !== 'layoutMode'
})<
  {
    layoutMode?: 'responsive' | 'fixed'
  } & CardContentProps
>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  width: 100%;

  ${({ layoutMode = 'fixed' }) => {
    if (layoutMode === 'fixed') {
      return css`
        gap: 4px;
        padding-bottom: 8px;
        padding-right: 8px;
      `
    }

    if (layoutMode === 'responsive') {
      return css`
        gap: 2px;
        padding-bottom: 4px;
        padding-right: 4px;

        @media (min-width: 600px) {
          gap: 4px;
          padding-bottom: 8px;
          padding-right: 8px;
        }
      `
    }
  }}
`

export const StyledClosedEventContainer = styled('div')`
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
`

export const StyledClosedEvent = styled('div')`
  position: absolute;
  width: 100%;
  height: calc(100% - 30px);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--Neutral4);
`

export const StyledClosedEventBadge = styled('div')`
  padding: 8px 14px;
  border-radius: 8px;
  background: var(--Neutral7);
`

export const StyledBadgeStack = styled('div', {
  shouldForwardProp: propName => propName !== 'layoutMode'
})<{
  layoutMode?: 'responsive' | 'fixed'
}>`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;

  ${({ layoutMode = 'fixed' }) => {
    if (layoutMode === 'fixed') {
      return css`
        gap: 4px;
      `
    }

    if (layoutMode === 'responsive') {
      return css`
        gap: 2px;

        @media (min-width: 600px) {
          gap: 4px;
        }
      `
    }
  }}
`

export const StyledSoldOutBadge = styled('div', {
  shouldForwardProp: propName => propName !== 'layoutMode'
})<{
  layoutMode?: 'responsive' | 'fixed'
}>`
  display: flex;
  justify-content: center;
  color: var(--Neutral10);
  background: var(--Neutral4);

  ${({ layoutMode = 'fixed' }) => {
    if (layoutMode === 'fixed') {
      return css`
        border-radius: 6px;
        padding: 7px 16px;
      `
    }

    if (layoutMode === 'responsive') {
      return css`
        border-radius: 4px;
        padding: 5px 16px;

        @media (min-width: 600px) {
          border-radius: 6px;
          padding: 7px 16px;
        }
      `
    }
  }}
`
