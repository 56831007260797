import { forwardRef, useCallback } from 'react'

import { Stack, Tooltip } from '@mui/material'

import { type IInstantWinCardProps } from './InstantWinCard.types'
import {
  StyledCard,
  StyledPrizeNameTypography,
  StyledThumbnailPicture,
  StyledTicketStatusBadge,
  StyledWinnerBadge,
  StyledWinnerTitle,
  StyledWinningTicketBadge
} from './InstantWinCard.styled'
import { getLocalAssetFromCDN } from '@helpers/index'

const InstantWinCard = forwardRef<HTMLDivElement, IInstantWinCardProps>(
  (props, ref) => {
    const { prize, isPast = false } = props

    const renderWinnerSection = useCallback(() => {
      return (
        <>
          <Stack direction="column" spacing={1}>
            <StyledWinnerTitle
              fontFamily="Poppins"
              fontSize="12px"
              lineHeight="16px"
              fontWeight={400}
            >
              Winning ticket
            </StyledWinnerTitle>

            <StyledWinningTicketBadge
              fontFamily="Poppins"
              fontSize="12px"
              lineHeight="16px"
              fontWeight={500}
              padding={{
                xs: '8px 12px'
              }}
              borderRadius={{
                sm: '8px',
                xs: '4px'
              }}
            >
              {prize.ticketLabel}
            </StyledWinningTicketBadge>
          </Stack>

          <Stack direction="column" spacing={1}>
            <StyledWinnerTitle
              fontFamily="Poppins"
              fontSize="12px"
              lineHeight="16px"
              fontWeight={400}
            >
              Winner
            </StyledWinnerTitle>

            <StyledWinnerBadge
              fontFamily="Poppins"
              fontSize="12px"
              lineHeight="16px"
              fontWeight={500}
              padding={{
                xs: '8px 12px'
              }}
              borderRadius={{
                sm: '8px',
                xs: '4px'
              }}
            >
              {prize.winnerDisplayName}
            </StyledWinnerBadge>
          </Stack>
        </>
      )
    }, [prize])

    return (
      <StyledCard
        ref={ref}
        borderRadius={{
          sm: '16px',
          xs: '8px'
        }}
        width="100%"
        overflow="hidden"
        justifyContent="center"
      >
        <Stack
          spacing={{
            sm: 0,
            xs: 1
          }}
          direction={{
            sm: 'row',
            xs: 'column'
          }}
          width="100%"
          overflow="hidden"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            width="100%"
            overflow="hidden"
          >
            <StyledThumbnailPicture
              hasWon={prize.hasWon}
              image={prize.prizeImage}
              alt={prize.prizeImage.altText}
              fallbackSrc={getLocalAssetFromCDN(
                '/assets/img/competition-default-image.webp'
              )}
            />

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              width="100%"
              overflow="hidden"
            >
              <Stack
                direction="column"
                justifyContent="flex-start"
                spacing={1}
                width="100%"
                overflow="hidden"
              >
                <Tooltip title={prize.prizeName}>
                  <StyledPrizeNameTypography
                    hasWon={prize.hasWon}
                    fontFamily="Poppins"
                    fontWeight={600}
                    fontSize={{
                      lg: '20px',
                      md: '19px',
                      sm: '18px',
                      xs: '17px'
                    }}
                    lineHeight={{
                      lg: '25px',
                      md: '23px',
                      sm: '22px',
                      xs: '20px'
                    }}
                  >
                    {prize.prizeName}
                  </StyledPrizeNameTypography>
                </Tooltip>

                {prize.ticketNumber && (
                  <Stack
                    direction="row"
                    spacing={2}
                    display={{
                      sm: 'flex',
                      xs: 'none'
                    }}
                  >
                    {renderWinnerSection()}
                  </Stack>
                )}
              </Stack>

              {!isPast && !prize.hasWon && (
                <StyledTicketStatusBadge
                  display={{
                    sm: 'block',
                    xs: 'none'
                  }}
                  fontFamily="Poppins"
                  fontWeight={500}
                  minWidth="130px"
                  height="48px"
                  fontSize="20px"
                  lineHeight="26px"
                  borderRadius="8px"
                  padding="11px 16px"
                >
                  NOT WON
                </StyledTicketStatusBadge>
              )}
            </Stack>
          </Stack>

          <Stack
            spacing={1}
            display={{
              sm: 'none',
              xs: 'flex'
            }}
          >
            {prize.ticketNumber && (
              <Stack direction="row" spacing={2}>
                {renderWinnerSection()}
              </Stack>
            )}

            {!isPast && !prize.hasWon && (
              <StyledTicketStatusBadge
                fontFamily="Poppins"
                fontWeight={500}
                height="26px"
                fontSize="14px"
                lineHeight="18px"
                borderRadius="4px"
                padding="4px 16px"
              >
                NOT WON
              </StyledTicketStatusBadge>
            )}
          </Stack>
        </Stack>
      </StyledCard>
    )
  }
)

export default InstantWinCard
