import { useEffect, useRef } from 'react'

import useTrustpilot from '../../hooks/useTrustpilot'

const TrustBannerContainer = () => {
  const trustBoxRef = useRef(null)

  const { loadScript } = useTrustpilot({
    trustBoxRef
  })

  useEffect(() => {
    /**
     *  If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
     *  If it's not, it means the script you pasted into <head /> isn't loaded just yet.
     *  When it is, it will automatically load the TrustBox.
     */
    if (/complete/.test(document.readyState)) {
      loadScript()
    } else {
      window.addEventListener('load', loadScript)
    }

    return () => {
      window.removeEventListener('load', loadScript)
    }
  }, [])

  return (
    <div
      ref={trustBoxRef}
      id="trustbox-banner"
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="5406e65db0d04a09e042d5fc"
      data-businessunit-id="5bd1a072a237c500018713c0"
      data-style-height="24px"
      data-style-width="100%"
      data-theme="dark"
    >
      <noscript>
        <a
          href="https://uk.trustpilot.com/review/elitecompetitions.co.uk"
          rel="noopener"
        >
          Trustpilot
        </a>
      </noscript>
    </div>
  )
}

export default TrustBannerContainer
