import { useStaticRendering } from 'mobx-react'

import { AuthStore, authStoreFactory } from './Auth'
import { NotificationStore, notificationStoreFactory } from './Notification'
import { CartStore, cartStoreFactory } from './Cart'

const isServer = typeof window === 'undefined'

useStaticRendering(isServer)

export type ComposedStore = {
  authStore: AuthStore
  notificationStore: NotificationStore
  cartStore: CartStore
}

let store: ComposedStore | null = null

export default function initializeStore(
  initialStore: ComposedStore | null = null
) {
  if (!isServer && store !== null) {
    return store
  }

  const _store: ComposedStore = {
    authStore: authStoreFactory(initialStore?.authStore || null),
    notificationStore: notificationStoreFactory(),
    cartStore: cartStoreFactory()
  }

  if (isServer) {
    return _store
  }

  if (store === null) {
    store = _store
  }

  return store
}
