import { isNaN, isNil } from 'lodash-es'

import { DateTime } from 'luxon'

import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'

import { useMemo, useState } from 'react'

import { TextField, Skeleton } from '@components/ui/atoms'

import { StyledPickersLayout, StyledPaper } from './DatePicker.styled'
import { IDatePickerProps } from './DatePicker.types'

const DatePicker = (props: IDatePickerProps) => {
  const {
    mode = 'dark',
    isLoading = false,
    isError = false,
    maxDate,
    value,
    ...otherProps
  } = props

  const [open, setOpen] = useState(false)

  const eligibleYear = useMemo(() => {
    if (!isNil(maxDate)) {
      return maxDate
    }

    const now = DateTime.now()

    return now.minus({
      years: 18
    })
  }, [])

  const isDateSelected = useMemo(() => {
    return !isNaN(value.valueOf())
  }, [value])

  if (isLoading) {
    return <Skeleton mode={mode} animation="pulse" width="100%" height={40} />
  }

  return (
    <MuiDatePicker
      format="dd/MM/yyyy"
      value={value}
      maxDate={eligibleYear}
      open={open}
      onClose={() => setOpen(false)}
      slotProps={{
        textField: props => ({
          mode,
          isLoading,
          isError,
          fullWidth: true,
          isDateSelected: isDateSelected,
          isDatePickerTextField: true,
          inputProps: {
            ...props.inputProps,
            readOnly: true
          },
          onClick: () => setOpen(true)
        })
      }}
      slots={{
        desktopPaper: StyledPaper,
        mobilePaper: StyledPaper,
        layout: StyledPickersLayout,
        textField: TextField
      }}
      {...otherProps}
    />
  )
}

export default DatePicker
