import { useCallback } from 'react'

import { Stack } from '@mui/material'

import { Checkbox, Typography, Skeleton } from '@components/ui/atoms'

import { ICommunicationConsentProps } from './CommunicationConsent.types'

const CommunicationConsent = (props: ICommunicationConsentProps) => {
  const {
    additionalInfo,
    isMultipleMode = false,
    isLoading = false,
    hasOptedForMarketingEmailsAndSMSMarketing = true,
    hasOptedForEmailMarketing = true,
    hasOptedForSMSMarketing = true,
    onChange
  } = props

  const renderCheckboxGroups = useCallback(() => {
    if (isMultipleMode) {
      if (isLoading) {
        return <Skeleton animation="pulse" width="100%" height={20} />
      }

      return (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap="8px"
        >
          <Checkbox
            disabled={isLoading}
            checked={hasOptedForMarketingEmailsAndSMSMarketing}
            onChange={() => {
              onChange(
                'hasOptedForMarketingEmailsAndSMSMarketing',
                !hasOptedForMarketingEmailsAndSMSMarketing
              )
            }}
          />

          <Typography
            color="var(--Neutral3)"
            fontVariant="body-3"
            weight="regular"
          >
            Opt me in for marketing Emails and SMS marketing
          </Typography>
        </Stack>
      )
    }

    if (isLoading) {
      return (
        <>
          <Skeleton animation="pulse" width="100%" height={20} />

          <Skeleton animation="pulse" width="100%" height={20} />
        </>
      )
    }

    return (
      <>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap="8px"
        >
          <Checkbox
            disabled={isLoading}
            checked={hasOptedForEmailMarketing}
            onChange={() => {
              onChange('hasOptedForEmailMarketing', !hasOptedForEmailMarketing)
            }}
          />

          <Typography
            color="var(--Neutral3)"
            fontVariant="body-3"
            weight="regular"
          >
            Opt me in for marketing emails
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap="8px"
        >
          <Checkbox
            disabled={isLoading}
            checked={hasOptedForSMSMarketing}
            onChange={() => {
              onChange('hasOptedForSMSMarketing', !hasOptedForSMSMarketing)
            }}
          />

          <Typography
            color="var(--Neutral3)"
            fontVariant="body-3"
            weight="regular"
          >
            Opt me in for SMS marketing
          </Typography>
        </Stack>
      </>
    )
  }, [
    isMultipleMode,
    isLoading,
    hasOptedForEmailMarketing,
    hasOptedForSMSMarketing,
    hasOptedForMarketingEmailsAndSMSMarketing
  ])

  return (
    <Stack direction="column" gap="16px">
      {renderCheckboxGroups()}

      {additionalInfo && (
        <Typography
          color="var(--Neutral3)"
          fontVariant="body-3"
          weight="regular"
        >
          {additionalInfo}
        </Typography>
      )}
    </Stack>
  )
}

export default CommunicationConsent
