import { useCallback, useEffect, useMemo, useState } from 'react'

import { DateTime } from 'luxon'

import { Stack } from '@mui/material'

import { IInstaPrizeCountdownTimerProps } from './InstaPrizeCountdownTimer.types'
import {
  StyledDigitTypography,
  StyledTimeTextTypography,
  StyledTimerContainer
} from './InstaPrizeCountdownTimer.styled'

const InstaPrizeCountdownTimer = (props: IInstaPrizeCountdownTimerProps) => {
  const {
    transactionDateTimeIso,
    durationInMinutes,
    onEndTimer,
    ...otherProps
  } = props

  const [timeLeft, setTimeLeft] = useState(0)

  const calculateTimeLeft = useCallback(() => {
    const diffInSeconds = DateTime.fromISO(transactionDateTimeIso)
      .plus({ minutes: durationInMinutes })
      .diff(DateTime.now(), 'seconds').seconds

    if (diffInSeconds > 0) {
      setTimeLeft(diffInSeconds)
    } else {
      setTimeLeft(0)

      onEndTimer()
    }
  }, [transactionDateTimeIso, setTimeLeft])

  useEffect(() => {
    calculateTimeLeft()

    const intervalId = setInterval(calculateTimeLeft, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const mins = useMemo(() => {
    return Math.floor(timeLeft / 60)
  }, [timeLeft])

  const secs = useMemo(() => {
    return Math.floor(timeLeft % 60)
  }, [timeLeft])

  return (
    <StyledTimerContainer {...otherProps}>
      <Stack direction="row" alignItems="flex-end" justifyContent="center">
        <StyledDigitTypography
          length={`${mins}`.length}
          fontVariant="heading-3"
          weight="semibold"
        >
          {`${mins < 10 ? '0' : ''}${mins}`}
        </StyledDigitTypography>

        <StyledTimeTextTypography
          lineHeight={{
            lg: '22px',
            md: '22px',
            sm: '20px',
            xs: '20px'
          }}
          fontVariant="body-4"
          weight="regular"
        >
          Mins
        </StyledTimeTextTypography>
      </Stack>

      <Stack direction="row" alignItems="flex-end" justifyContent="center">
        <StyledDigitTypography
          length={`${secs}`.length}
          fontVariant="heading-3"
          weight="semibold"
        >
          {`${secs < 10 ? '0' : ''}${secs}`}{' '}
        </StyledDigitTypography>

        <StyledTimeTextTypography
          lineHeight={{
            lg: '22px',
            md: '22px',
            sm: '20px',
            xs: '20px'
          }}
          fontVariant="body-4"
          weight="regular"
        >
          Secs
        </StyledTimeTextTypography>
      </Stack>
    </StyledTimerContainer>
  )
}

export default InstaPrizeCountdownTimer
