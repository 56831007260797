import { orderBy } from 'lodash-es'

import { type CompetitionBaseDto } from '@elitecompetitions/core-api'

export const sortByHighValueAndSoldOut = (
  competitions: CompetitionBaseDto[] = []
): CompetitionBaseDto[] => {
  return orderBy(
    competitions,
    ['isSoldOut', _ => _.ticketPrice * _.ticketsCount],
    ['asc', 'desc']
  )
}
