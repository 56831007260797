import { IconButton, styled, css } from '@mui/material'

export const StyledIconButton = styled(IconButton)<{
  mode?: 'light' | 'dark'
}>`
  padding: 0;
  cursor: pointer;
  border-radius: 16px;

  ${({ mode = 'light' }) => {
    if (mode === 'light') {
      return css`
        color: var(--Neutral10);

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: var(--Neutral2);
          }
        }

        &:active {
          background-color: var(--Neutral4);
        }
      `
    }

    return css`
      color: var(--Neutral1);

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: var(--Neutral6);
        }
      }

      &:active {
        background-color: var(--Neutral9);
      }
    `
  }}
`
