import Skeleton from '../Skeleton'

/**
 * @deprecated
 */
const InputField = props => {
  const {
    isLoading = false,
    className,
    type,
    value,
    name,
    placeholder,
    onChange,
    iconClass,
    disabled = false,
    required,
    pattern = '',
    ...restProps
  } = props

  let inputProps = {}

  if (pattern) {
    inputProps = {
      pattern
    }
  }

  return (
    <div className={`input-field ${className || ''}`}>
      <div className="input-wrap">
        {isLoading ? (
          <Skeleton animation="pulse" width="100%" height={60} />
        ) : (
          <>
            <i className={iconClass} />

            <input
              type={type}
              name={name}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              disabled={disabled}
              required={required}
              {...inputProps}
              {...restProps}
              className="col-12"
            />
          </>
        )}
      </div>
    </div>
  )
}

export default InputField
