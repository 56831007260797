import { isEmpty } from 'lodash-es'

import { Fragment, useCallback } from 'react'

import { Carousel } from 'react-responsive-carousel'

import Link from 'next/link'

import { CdnImage } from '@components/ui/molecules/CdnImage'
import { HomeCarouselBadge } from '@components/ui/atoms'

import { Stack } from '@mui/material'

import { sortByHighValueAndSoldOut } from '@utils/Competition'
import * as FBPixel from '@utils/FBPixel'

import { getFBPixelEventID, getLocalAssetFromCDN } from '@helpers'
import { isDrawToday } from '@helpers/competitions/splitCompetitions'

import { CurrencyTypeEnum } from '@enums'

import { type CompetitionBaseDto } from '@elitecompetitions/core-api'

import {
  StyledCard,
  StyledBadgeContainer,
  StyledBadgeStack,
  StyledCardMediaContainer,
  StyledTypography,
  StyledCardMedia
} from './HomeCarousel.styled'
import { IHomeCarouselProps } from './HomeCarousel.types'

const HomeCarousel = (props: IHomeCarouselProps) => {
  const { drawnTodayCompetitions = [], sortByHighValue = false } = props

  const getImagePlaceholder = useCallback((competition: CompetitionBaseDto) => {
    const { imagePlaceholder } = competition

    return isEmpty(imagePlaceholder) ? 'empty' : 'blur'
  }, [])

  /**
   * @link https://support.dataart.com/browse/ELITE-407
   */
  const renderHomeCarouselBadge = useCallback(
    (competition: CompetitionBaseDto) => {
      const { isAppOnly, isFree } = competition
      const isEndingSoon = isDrawToday(competition)

      if (isAppOnly && isFree && isEndingSoon) {
        return (
          <HomeCarouselBadge
            variant="appExclusive"
            badgeContent="App exclusive"
          />
        )
      }

      if (isAppOnly && isFree) {
        return (
          <HomeCarouselBadge
            variant="appExclusive"
            badgeContent="App exclusive"
          />
        )
      }

      if (isFree && isEndingSoon) {
        return (
          <HomeCarouselBadge
            variant="freeToEnter"
            badgeContent="Free to enter"
          />
        )
      }

      if (isEndingSoon) {
        return (
          <HomeCarouselBadge variant="endingSoon" badgeContent="Ending soon" />
        )
      }

      if (isFree) {
        return (
          <HomeCarouselBadge
            variant="freeToEnter"
            badgeContent="Free to enter"
          />
        )
      }

      if (isAppOnly) {
        return (
          <HomeCarouselBadge
            variant="appExclusive"
            badgeContent="App exclusive"
          />
        )
      }

      return null
    },
    []
  )

  const handleTrackViewContent = (competition: CompetitionBaseDto) => {
    FBPixel.track(
      FBPixel.FBPixelEventTypeEnum.VIEW_CONTENT,
      {
        content_name: competition.name,
        content_category: 'Home > Primary Carousel',
        contents: [
          {
            id: competition.firebaseId,
            quantity: 1
          }
        ],
        content_type: 'product',
        value: competition.ticketPrice,
        currency: CurrencyTypeEnum.GBP
      },
      {
        eventID: getFBPixelEventID()
      }
    )
  }

  const renderHomeUpComingLiveDrawLists = useCallback(() => {
    let competitionLists = [...drawnTodayCompetitions]

    if (sortByHighValue) {
      competitionLists = sortByHighValueAndSoldOut(drawnTodayCompetitions)
    }

    return competitionLists.slice(0).map((competition, index) => {
      return (
        <Fragment key={competition.id}>
          <Link href={`/competitions/${competition.slugUrl}`} prefetch={false}>
            <a>
              <Stack spacing={2}>
                <StyledCard
                  onClick={() => {
                    handleTrackViewContent(competition)
                    handleTrackViewContent(competition)
                  }}
                >
                  <StyledCardMediaContainer>
                    <StyledCardMedia>
                      <CdnImage
                        loading={index === 0 ? 'eager' : 'lazy'}
                        alt={competition.name}
                        image={competition.cdnImage}
                        placeholder={getImagePlaceholder(competition)}
                        fallbackSrc={getLocalAssetFromCDN(
                          '/assets/img/competition-default-image.webp'
                        )}
                      />
                    </StyledCardMedia>

                    <StyledBadgeContainer>
                      <StyledBadgeStack>
                        {renderHomeCarouselBadge(competition)}
                      </StyledBadgeStack>
                    </StyledBadgeContainer>
                  </StyledCardMediaContainer>
                </StyledCard>

                <StyledTypography
                  fontWeight={500}
                  fontSize="20px"
                  lineHeight="26px"
                >
                  {competition.name}
                </StyledTypography>
              </Stack>
            </a>
          </Link>
        </Fragment>
      )
    })
  }, [
    drawnTodayCompetitions,
    sortByHighValue,
    getImagePlaceholder,
    renderHomeCarouselBadge
  ])

  return (
    <Carousel
      autoPlay={true}
      showArrows={true}
      emulateTouch={true}
      infiniteLoop={true}
      interval={10000}
      showThumbs={false}
      stopOnHover={true}
      showIndicators={false}
      showStatus={false}
      preventMovementUntilSwipeScrollTolerance={true}
      swipeScrollTolerance={50}
    >
      {renderHomeUpComingLiveDrawLists()}
    </Carousel>
  )
}

export default HomeCarousel
