import { isNil } from 'lodash-es'

import * as Sentry from '@sentry/nextjs'

import config from '@services/config'

import { type UserProfile } from '@elitecompetitions/core-api'

export enum SCPixelEventTypeEnum {
  ADD_CART = 'ADD_CART',
  PAGE_VIEW = 'PAGE_VIEW',
  PURCHASE = 'PURCHASE',
  SIGN_UP = 'SIGN_UP',
  VIEW_CONTENT = 'VIEW_CONTENT',
  START_CHECKOUT = 'START_CHECKOUT'
}

export const init = (profile: UserProfile) => {
  if (isNil(profile)) {
    return false
  }

  const { email } = profile

  try {
    if (typeof window !== 'undefined' && typeof window.snaptr === 'function') {
      window.snaptr('init', config.snapchat.id, {
        user_email: email
      })
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const pageView = () => {
  try {
    if (typeof window !== 'undefined' && typeof window.snaptr === 'function') {
      window.snaptr('track', SCPixelEventTypeEnum.PAGE_VIEW)
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const track = <T = any>(eventName: string, eventData: T) => {
  try {
    if (typeof window !== 'undefined' && typeof window.snaptr === 'function') {
      window.snaptr('track', eventName, eventData)
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}
