import { UserProfile } from '@elitecompetitions/core-api'

const hotjarUserIdentificationEvent = ({
  isLoggedIn,
  profile
}: {
  isLoggedIn: boolean
  profile: UserProfile | null
}) => {
  if (isLoggedIn && profile) {
    try {
      const email = profile.email

      if (typeof window !== 'undefined' && window.hj) {
        window.hj('identify', email, {
          firebaseUid: profile.userId
        })
      }
    } catch (err) {
      console.error('Error in hotjarUserIdentificationEvent', err)
    }
  }
}
export default hotjarUserIdentificationEvent
