import { Box, styled } from '@mui/material'

import { ThumbnailPicture, Typography } from '@components/ui/atoms'

export const StyledCard = styled(Box)`
  display: flex;
  align-items: center;
  background: var(--Neutral8);
  padding: 8px;
`

export const StyledWinningBadge = styled(Typography)`
  text-align: left;
  text-wrap: nowrap;
  backdrop-filter: blur(34px);
  max-height: 32px;
  padding: 8px 12px;
  border-radius: 8px;
  max-width: fit-content;
`

export const StyledWinningDateBadge = styled(StyledWinningBadge)`
  background: var(--Third);
  color: var(--SecondaryVariant);
`

export const StyledWinningTicketBadge = styled(StyledWinningBadge)`
  color: var(--Neutral1);
  background: var(--SecondaryVariant);
`

export const StyledWinningCreditDateBadge = styled(StyledWinningBadge)`
  background: var(--Neutral6);
  color: var(--Neutral1);
`

export const StyledPrizeNameTypography = styled(Typography)`
  color: var(--Neutral1);
  word-wrap: break-word;
  hyphens: auto;
  overflow-wrap: break-word;
  white-space: wrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
`

export const StyledWinnerTitle = styled(Typography)`
  color: var(--Neutral2);
`

export const StyledThumbnailPicture = styled(ThumbnailPicture)`
  max-height: 165px;
  object-fit: cover;
  border-radius: 6px;

  @media (min-width: 600px) {
    border-radius: 12px;
  }
`
