import { useCallback } from 'react'

import { Badge } from '@components/ui/atoms'

import { Stack, Typography } from '@mui/material'

import { IDiscountPriceBadge } from './DiscountPriceBadge.types'
import { StyledOriginalPriceTypography } from './DiscountPriceBadge.styled'

const DiscountPriceBadge = (props: IDiscountPriceBadge) => {
  const { originalPrice, discountPrice, layoutMode = 'fixed' } = props

  const renderBadgeContent = useCallback(() => {
    if (layoutMode === 'fixed') {
      return (
        <>
          <StyledOriginalPriceTypography
            fontFamily="Poppins"
            fontWeight={400}
            lineHeight="20px"
            fontSize="16px"
          >
            {`£${originalPrice}`}
          </StyledOriginalPriceTypography>

          <Typography
            fontFamily="Poppins"
            fontWeight={700}
            lineHeight="20px"
            fontSize="16px"
          >
            {`£${discountPrice}`}
          </Typography>
        </>
      )
    }

    if (layoutMode === 'responsive') {
      return (
        <>
          <StyledOriginalPriceTypography
            fontFamily="Poppins"
            fontWeight={400}
            lineHeight={{
              lg: '20px',
              md: '18px',
              sm: '16px',
              xs: '14px'
            }}
            fontSize={{
              lg: '16px',
              md: '14px',
              sm: '12px',
              xs: '10px'
            }}
          >
            {`£${originalPrice}`}
          </StyledOriginalPriceTypography>

          <Typography
            fontFamily="Poppins"
            fontWeight={700}
            lineHeight={{
              lg: '20px',
              md: '18px',
              sm: '16px',
              xs: '14px'
            }}
            fontSize={{
              lg: '16px',
              md: '14px',
              sm: '12px',
              xs: '10px'
            }}
          >
            {`£${discountPrice}`}
          </Typography>
        </>
      )
    }

    return null
  }, [layoutMode, originalPrice, discountPrice])

  return (
    <Badge variant="price" layoutMode={layoutMode}>
      <Stack direction="row" spacing={0.5}>
        {renderBadgeContent()}
      </Stack>
    </Badge>
  )
}

export default DiscountPriceBadge
