import { styled } from '@mui/material'

/**
 * @deprecated
 */
const Section = styled('div')`
  padding-bottom: 0px;
  padding-top: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
`

export default Section
