import { useMemo, useState, type ChangeEvent } from 'react'

import { keyBy, isNil } from 'lodash-es'

import { Checkbox, FormGroup, FormControlLabel } from '@mui/material'
import InfoOutlined from '@mui/icons-material/InfoOutlined'

import { RedeemInstaPrizeCard } from '@components/ui/molecules'
import { Notice, Button, Typography } from '@components/ui/atoms'

import {
  StyledBackButton,
  StyledCheckboxLabel,
  StyledDialogActions,
  StyledDisclaimerNote,
  StyledSectionButtons,
  StyledDialogContent
} from './RedeemDialogForm.styled'
import { IRedeemDialogFormProps } from './RedeemDialogForm.types'

const RedeemDialogForm = (props: IRedeemDialogFormProps) => {
  const {
    onClose,
    onSubmit,
    redeemTotalSum = 0,
    isLoadingSwapCash = false,
    competitionsOfInstantPrizes = [],
    instantPrizes = []
  } = props

  const [isTermsAgreed, setIsTermsAgreed] = useState(false)

  const competitionsOfInstantPrizesMap = useMemo(() => {
    return keyBy(competitionsOfInstantPrizes, 'firebaseId')
  }, [competitionsOfInstantPrizes])

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsTermsAgreed(event.target.checked)
  }

  const handleCloseDialog = () => {
    setIsTermsAgreed(false)
    onClose(false)
  }

  return (
    <>
      <Notice>
        <InfoOutlined />

        <Typography
          fontWeight={400}
          lineHeight={{
            lg: '20px',
            md: '18px',
            sm: '16px',
            xs: '16px'
          }}
          fontSize={{
            lg: '16px',
            md: '16px',
            sm: '16px',
            xs: '12px'
          }}
        >
          Just a heads up, credit cannot be withdrawn and must be spent on the
          competitions.
        </Typography>
      </Notice>

      <Typography
        fontWeight={400}
        fontSize={{
          lg: '20px',
          md: '16px',
          sm: '14px',
          xs: '12px'
        }}
      >
        The prize(s) below will be converted to the wallet credit.
      </Typography>

      <StyledDialogContent>
        {instantPrizes.map((instantPrize, idx) => {
          const competition =
            competitionsOfInstantPrizesMap[instantPrize.competitionKey]

          if (!isNil(competition)) {
            return (
              <RedeemInstaPrizeCard
                key={`redeem-insta-prize-card-${idx}`}
                competition={competition}
                instantPrize={instantPrize}
              />
            )
          }

          return null
        })}
      </StyledDialogContent>

      <StyledDialogActions>
        <FormGroup>
          <Typography
            fontWeight={500}
            fontSize={{
              lg: '24px',
              md: '20px',
              sm: '18px',
              xs: '16px'
            }}
            lineHeight={{
              lg: '32px',
              md: '28px',
              sm: '26px',
              xs: '22px'
            }}
          >
            {`Total amount: £${redeemTotalSum}`}
          </Typography>

          <FormControlLabel
            label={
              <StyledCheckboxLabel fontVariant="body-4" weight="regular">
                I understand that once converted to credit the balance cannot be
                withdrawn.
                <br />
                <StyledDisclaimerNote fontVariant="body-4" weight="regular">
                  Please note: this action can’t be reversed.
                </StyledDisclaimerNote>
              </StyledCheckboxLabel>
            }
            control={
              <Checkbox
                color="success"
                checked={isTermsAgreed}
                disabled={isLoadingSwapCash}
                onChange={handleCheckboxChange}
              />
            }
          />
        </FormGroup>

        <StyledSectionButtons>
          <StyledBackButton
            mode="light"
            type="button"
            variant="secondary"
            onClick={handleCloseDialog}
          >
            BACK
          </StyledBackButton>

          <Button
            type="button"
            size="large"
            variant="primary"
            disabled={!isTermsAgreed || isLoadingSwapCash}
            onClick={onSubmit}
          >
            {isLoadingSwapCash ? 'Loading...' : `REDEEM £${redeemTotalSum}`}
          </Button>
        </StyledSectionButtons>
      </StyledDialogActions>
    </>
  )
}

export default RedeemDialogForm
