import * as Sentry from '@sentry/nextjs'

import { UserProfile } from '@elitecompetitions/core-api'

const setLogContext = (customer: UserProfile) => {
  const customerObj = customer
    ? {
        id: customer.userId,
        email: customer.email,
        firstName: customer.firstName,
        lastName: customer.lastName
      }
    : {}

  Sentry.setContext('customer_details', customerObj)
}

const setPage = page => {
  Sentry.setContext('page', { name: page })
}

export { setPage, setLogContext }
