import { styled, Dialog, Box, css } from '@mui/material'

export const StyledDialog = styled(Dialog)`
  & .MuiDialog-container {
    & .MuiPaper-root {
      width: fit-content;
      height: fit-content;
      padding: 0 24px 24px 24px;
      border-radius: 8px;
      background: var(--Neutral8);
    }
  }
`

export const StyledLottieContainer = styled(Box)`
  max-height: 50px;

  ${({
    theme: {
      breakpoints: {
        values: { xs, sm }
      }
    }
  }) => {
    return css`
      @media (min-width: ${xs}px) {
        transform: scale(0.3);
        margin-bottom: 10px;
      }

      @media (min-width: ${sm}px) {
        margin-bottom: 30px;
      }
    `
  }}
`
