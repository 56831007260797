import { css, Box, styled } from '@mui/material'

import { ITypographyProps } from '@components/ui/atoms/Typography/Typography.types'

import Typography from '../Typography'

export const StyledTypography = styled(Typography)`
  color: var(--Neutral1);
`

export const StyledDigitTypography = styled(StyledTypography, {
  shouldForwardProp: propName => propName !== 'length'
})<
  {
    length: number
  } & ITypographyProps
>`
  ${({ length }) => {
    return css`
      width: ${length === 1 || length === 0 ? 2 : length}ch;
    `
  }}
`

export const StyledTimeTextTypography = styled(StyledTypography)`
  padding-left: 2px;
`

export const StyledTimerContainer = styled(Box)`
  display: flex;
  justify-content: center;
  border-radius: 8px;
  background: var(--Secondary);
  backdrop-filter: blur(34px);
  padding: 4px 16px;
  gap: 16px;
`
