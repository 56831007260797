import Skeleton from '../Skeleton'

import { type ISelectProps } from './Select.types'
import { StyledSelect } from './Select.styled'

const MenuProps = {
  sx: {
    '& .MuiPaper-root': {
      marginTop: '8px',
      borderRadius: '8px',
      '.MuiButtonBase-root': {
        font: '14px',
        weight: '500',
        lineHeight: '18px',
        height: '38px',
        color: 'var(--Neutral1)',
        margin: '8px',
        '&.Mui-selected': {
          backgroundColor: 'var(--SecondaryVariant) !important',
          borderRadius: '8px'
        },
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover, &:active': {
            backgroundColor: 'var(--Neutral7)',
            borderRadius: '8px'
          }
        }
      },
      backgroundColor: 'var(--Neutral8)'
    }
  }
}

const Select = (props: ISelectProps) => {
  const {
    mode = 'light',
    isLoading = false,
    isError = false,
    isSuccess = false,
    ...otherProps
  } = props

  if (isLoading) {
    return <Skeleton mode={mode} animation="pulse" width="100%" height={40} />
  }

  return (
    <StyledSelect
      mode={mode}
      variant="outlined"
      MenuProps={MenuProps}
      isError={isError}
      isSuccess={isSuccess}
      {...otherProps}
    />
  )
}

export default Select
