import { Box, Stack } from '@mui/material'

import { Typography } from '@components/ui/atoms'

import {
  StyledCard,
  StyledInfoLabel,
  StyledThumbnailPicture
} from './RedeemInstaPrizeCard.styled'
import { IRedeemInstaPrizeCardProps } from './RedeemInstaPrizeCard.types'

const RedeemInstaPrizeCard = (props: IRedeemInstaPrizeCardProps) => {
  const { instantPrize, competition } = props

  return (
    <StyledCard>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap={1}
      >
        <Box
          display={{
            xs: 'none',
            sm: 'block'
          }}
        >
          <StyledThumbnailPicture
            cdnImagesObj={instantPrize.image.images}
            alt={instantPrize.image.altText}
            loading="eager"
            preferredSize="small"
          />
        </Box>

        <Stack
          direction="column"
          alignItems="flex-start"
          width="100%"
          spacing={1}
        >
          <Typography
            fontVariant="heading-4"
            weight="semibold"
            color="var(--Neutral1)"
          >
            {instantPrize.name}
          </Typography>

          <Stack direction="row" justifyContent="flex-start" gap={2}>
            <Stack direction="column" alignItems="flex-start" gap={1}>
              <Typography
                fontVariant="body-3"
                weight="regular"
                color="var(--Neutral2)"
              >
                Ticket number
              </Typography>

              <StyledInfoLabel fontVariant="body-3" weight="semibold">
                {instantPrize.ticketNumber}
              </StyledInfoLabel>
            </Stack>

            <Stack direction="column" alignItems="flex-start" gap={1}>
              <Typography
                fontVariant="body-3"
                weight="regular"
                color="var(--Neutral2)"
              >
                Competition name
              </Typography>

              <Typography
                fontVariant="body-2"
                weight="medium"
                color="var(--Neutral1)"
              >
                {competition.name}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </StyledCard>
  )
}

export default RedeemInstaPrizeCard
