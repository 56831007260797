import { Typography } from '@components/ui/atoms'

import { type ITitleProps } from './Title.types'

const Title = (props: ITitleProps) => {
  const { variant = 'h2', children, ...otherPros } = props

  return (
    <Typography
      fontVariant="heading-2"
      weight="semibold"
      variant={variant}
      {...otherPros}
    >
      {children}
    </Typography>
  )
}

export default Title
