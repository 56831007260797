import * as Sentry from '@sentry/nextjs'

import { CurrencyTypeEnum } from '@enums'

import {
  type CompetitionBaseDto,
  type CompetitionDetailsDTO,
  type ReservedTicketDTO,
  type TicketBaseDTO
} from '@elitecompetitions/core-api'

import { getAppPlatform } from '.'

function paqPush(params) {
  try {
    window._paq = window._paq || []
    if (!params || !Array.isArray(params)) {
      throw new Error('params must be an array')
    }

    if (typeof _paq.push === 'function') {
      if (params[0] === 'trackEvent') {
        params[1] =
          typeof params[1] === 'string' && params[1].trim() !== ''
            ? params[1].replace(/\s+/g, '_')
            : 'default_category'
        params[2] =
          typeof params[2] === 'string' && params[2].trim() !== ''
            ? params[2].replace(/\s+/g, '_')
            : 'default_action'

        if (
          params[3] &&
          typeof params[3] === 'object' &&
          !Array.isArray(params[3])
        ) {
          params[3] = Object.entries(params[3]).reduce(
            (newPayload, [key, value]) => {
              if (typeof key === 'string' && value) {
                newPayload[key.replace(/\s+/g, '_')] = value
              }
              return newPayload
            },
            {}
          )
          params[3] = JSON.stringify(params[3])
        }
      }
      _paq.push(params)
    } else {
      throw new Error('_paq.push is not a function')
    }
  } catch (err) {
    console.error('utils.matomo.paqPush', err)
    Sentry.captureException(err)
  }
}

let cartItems = {}

export const matomo = {
  init: (uid, email) => {
    paqPush(['setUserId', uid])
    console.log('Matomo.init: initialization completed')
  },

  setUid: uid => {
    console.log('uid', uid)
    try {
      if (uid) {
        paqPush(['setUserId', uid])
      }
    } catch (err) {
      console.error('utils.matomo.setUid', err)
      Sentry.captureException(err)
    }
  },

  view_product: (item: CompetitionDetailsDTO) => {
    try {
      const price = parseFloat(item.ticketPrice)
      const value = parseFloat(
        item.isOnSale ? item.saleTicketPrice : item.ticketPrice
      )
      const productSKU = item.firebaseId
      const productName = item.name
      const categoryName = item.hasInstantPrizes
        ? 'insta-win'
        : 'regular-competition'
      const priceUpdated = item.isOnSale ? item.saleTicketPrice : price

      paqPush([
        'setEcommerceView',
        productSKU,
        productName,
        categoryName,
        priceUpdated
      ])

      // Followed by a trackPageView
      paqPush(['trackPageView'])
    } catch (error) {
      Sentry.captureException(error)
    }
  },

  add_to_cart: (
    item: CompetitionBaseDto,
    {
      overRideValue = false,
      val = 0,
      quantity = 1
    }: Partial<{
      overRideValue: boolean
      val: number
      quantity: number
    }>
  ) => {
    try {
      const price = parseFloat(item.ticketPrice)
      const value = overRideValue
        ? val
        : parseFloat(item.isOnSale ? item.saleTicketPrice : item.ticketPrice)

      const productSKU = item.firebaseId
      const productName = item.name
      const categoryName = item.hasInstantPrizes
        ? 'insta-win'
        : 'regular-competition'

      if (cartItems[productSKU]) {
        cartItems[productSKU].quantity += quantity
      } else {
        cartItems[productSKU] = {
          name: productName,
          category: categoryName,
          price: price,
          quantity: quantity
        }
      }

      paqPush([
        'addEcommerceItem',
        productSKU,
        productName,
        categoryName,
        price,
        cartItems[productSKU].quantity
      ])
      // Update the Cart's Total Value
      paqPush([
        'trackEcommerceCartUpdate',
        value * cartItems[productSKU].quantity
      ])
    } catch (error) {
      console.error('utils.matomo.add_to_cart', error)
    }
  },

  remove_from_cart: (item: CompetitionBaseDto) => {
    try {
      const productSKU = item.firebaseId

      paqPush(['removeEcommerceItem', productSKU])
    } catch (error) {
      console.error('utils.matomo.remove_from_cart', error)
      Sentry.captureException(error)
    }
  },

  begin_checkout: ({
    items,
    value,
    couponCode = ''
  }: {
    items: Array<{
      competition: CompetitionBaseDto
      tickets: ReservedTicketDTO[]
    }>
    couponCode: string
    value: number
  }) => {
    try {
      const cart_items = items.map(item => {
        const { competition, tickets } = item
        const { firebaseId, name, ticketPrice, isOnSale, saleTicketPrice } =
          competition

        return tickets.map(ticket => {
          return {
            item_id: firebaseId,
            item_name: name,
            price: ticketPrice,
            discount:
              ticketPrice -
              (ticket.recommendedPrice ||
                (isOnSale ? saleTicketPrice : ticketPrice))
          }
        })
      })

      paqPush([
        'trackEvent',
        'ecommerce',
        'begin_checkout',
        JSON.stringify({
          currency: CurrencyTypeEnum.GBP,
          platform: getAppPlatform(),
          value,
          coupon: couponCode ? couponCode : '',
          items: cart_items
        })
      ])
    } catch (error) {
      console.error('utils.matomo.begin_checkout', error)
      Sentry.captureException(error)
    }
  },

  add_payment_info: ({
    items,
    value,
    couponCode = '',
    paymentMethod
  }: {
    items: {
      competition: CompetitionBaseDto
      tickets: ReservedTicketDTO[]
    }[]
    value: number
    couponCode: string
    paymentMethod: string
  }) => {
    try {
      const cart_items = items
        .map(item => {
          const { competition, tickets } = item
          const { firebaseId, name, ticketPrice, isOnSale, saleTicketPrice } =
            competition

          return tickets.map(ticket => {
            return {
              item_id: firebaseId,
              item_name: name,
              price: ticketPrice,
              discount:
                ticketPrice -
                (ticket.recommendedPrice ||
                  (isOnSale ? saleTicketPrice : ticketPrice))
            }
          })
        })
        .flat()

      paqPush([
        'trackEvent',
        'ecommerce',
        'add_payment_info',
        JSON.stringify({
          payment_type: paymentMethod,
          currency: CurrencyTypeEnum.GBP,
          platform: getAppPlatform(),
          value,
          coupon: couponCode ? couponCode : '',
          items: cart_items
        })
      ])
    } catch (error) {
      console.error('utils.matomo.add_payment_info', error)
      Sentry.captureException(error)
    }
  },

  purchase: ({
    uniqueTickets,
    value,
    transactionId
  }: {
    uniqueTickets: {
      competition: CompetitionBaseDto
      tickets: TicketBaseDTO[]
    }[]
    value: number
    transactionId: string
  }) => {
    try {
      let items = {}

      uniqueTickets.forEach(item => {
        const { tickets, competition } = item

        const item_id = competition.firebaseId
        const item_name = competition.name
        const price = competition.ticketPrice

        tickets.forEach(() => {
          if (items[item_id]) {
            items[item_id].quantity += 1
          } else {
            items[item_id] = {
              name: item_name,
              price: price,
              quantity: 1
            }
          }
        })
      })

      for (let item_id in items) {
        const { name, price, quantity } = items[item_id]

        paqPush(['addEcommerceItem', item_id, name, '', price, quantity])
      }

      // Track the ecommerce order
      paqPush(['trackEcommerceOrder', transactionId, value])
    } catch (error) {
      console.error('utils.matomo.purchase', error)
      Sentry.captureException(error)
    }
  },

  customEvent: (eventName, payload) => {
    try {
      payload = typeof payload === 'object' ? JSON.stringify(payload) : payload
      paqPush(['trackEvent', 'default_category', eventName, payload])
    } catch (error) {
      console.error('utils.matomo.customEvent', error)
      Sentry.captureException(error)
    }
  }
}
