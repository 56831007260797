import { Box, css, styled, Typography } from '@mui/material'

import { CdnImage } from '@components/ui/molecules/CdnImage'

export const StyledCard = styled(Box)`
  display: flex;
  align-items: center;
  background: var(--Neutral8);
  padding: 8px;
  height: 100%;
  min-height: 100%;
`

export const StyledPrizeNameTypography = styled(Typography, {
  shouldForwardProp: propName => propName !== 'hasWon'
})<{ hasWon: boolean }>`
  color: var(--Neutral1);
  word-wrap: break-word;
  hyphens: auto;
  overflow-wrap: break-word;
  white-space: wrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;

  ${({ hasWon = false }) => {
    if (hasWon) {
      return css`
        color: var(--Neutral4);
      `
    }
  }}
`

export const StyledWinnerBadge = styled(Typography, {
  shouldForwardProp: propName => propName !== 'hasWon'
})<{
  hasWon?: boolean
}>`
  text-align: center;
  backdrop-filter: blur(34px);

  ${({ hasWon = false }) => {
    if (hasWon) {
      return css`
        background: var(--SecondaryVariant);
        color: var(--Neutral1);
      `
    }

    return css`
      background-color: var(--Neutral6);
      color: var(--Neutral2);
    `
  }}
`

export const StyledWinningTicketBadge = styled(Typography, {
  shouldForwardProp: propName => propName !== 'hasWon'
})<{
  hasWon?: boolean
}>`
  text-align: center;
  backdrop-filter: blur(34px);

  ${({ hasWon = false }) => {
    if (hasWon) {
      return css`
        background: var(--Third);
        color: var(--SecondaryVariant);
      `
    }

    return css`
      background-color: var(--Neutral6);
      color: var(--Neutral2);
    `
  }}
`

export const StyledWinnerTitle = styled(Typography)`
  color: var(--Neutral2);
`

export const StyledThumbnailPicture = styled(CdnImage, {
  shouldForwardProp: propName => propName !== 'hasWon'
})<{
  hasWon?: boolean
}>`
  max-width: 165px;
  object-fit: cover;
  border-radius: 6px;

  @media (min-width: 600px) {
    border-radius: 12px;
  }

  ${({ hasWon }) => {
    if (hasWon) {
      return css`
        filter: grayscale(80%);
      `
    }
  }}
`

export const StyledTicketStatusBadge = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  backdrop-filter: blur(34px);
  color: var(--Neutral1);
  background: var(--Secondary);
`
