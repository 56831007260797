import Image from 'next/image'

import { Carousel } from 'react-responsive-carousel'

import { Typography } from '@components/ui/atoms'
import { Loader } from '@components/ui/molecules'

import { Stack } from '@mui/material'

import { replaceImagePathTemplate } from '@helpers/competitions/replace-image-path-template'

import { ITestimonialsProps } from './Testimonials.types'
import { StyledSingleTestimonial } from './Testimonials.styled'

const Testimonials = (props: ITestimonialsProps) => {
  const { testimonials = [], loadingStatus = false, children } = props

  return (
    <Stack direction="column" spacing={2}>
      {children}

      {testimonials.length > 0 && !loadingStatus ? (
        <div className="row justify-content-center pb-5 testimonial">
          <div className="col col-md-8">
            <Carousel
              autoPlay={testimonials.length > 1}
              showArrows={testimonials.length > 1}
              emulateTouch={true}
              interval={5000}
              showThumbs={false}
              stopOnHover={true}
              showIndicators={true}
              showStatus={false}
            >
              {testimonials.map((testimonial, i) => {
                const {
                  winnerName,
                  prizeName,
                  winnerProfileCDNImage,
                  winnerResponse
                } = testimonial

                return (
                  <StyledSingleTestimonial key={`testimonial-key-${i}`}>
                    <Image
                      loading="lazy"
                      src={replaceImagePathTemplate(
                        winnerProfileCDNImage,
                        'medium',
                        'webp',
                        2
                      )}
                      width={600}
                      height={350}
                      alt={`${winnerName}-${prizeName}`}
                    />

                    <p>{winnerResponse}</p>

                    <div className="clients-info">
                      <h5>{winnerName}</h5>

                      <p>The Winner of {prizeName}</p>
                    </div>
                  </StyledSingleTestimonial>
                )
              })}
            </Carousel>
          </div>
        </div>
      ) : !loadingStatus ? (
        <div className="row justify-content-left" key="testimonials-loading">
          <div className="col-lg-12">
            No Testimonials to show at the moment!
          </div>
        </div>
      ) : (
        <Loader isOpen={true}>
          <Typography
            fontVariant="heading-3"
            weight="semibold"
            textAlign="center"
            color="var(--Neutral1)"
          >
            Loading...
          </Typography>
        </Loader>
      )}
    </Stack>
  )
}

export default Testimonials
