import { isNil } from 'lodash-es'

import { useCallback, useMemo } from 'react'

import Link from 'next/link'

import { round2Decimal } from '@utils'
import tracking from '@utils/tracking'

import { DiscountPriceBadge, CompetitionCardBadge } from '@components/ui/atoms'
import { CdnImage } from '@components/ui/molecules/CdnImage'

import { isDrawToday } from '@helpers/competitions/splitCompetitions'
import { getLocalAssetFromCDN } from '@helpers'

import { type CompetitionBaseDto } from '@elitecompetitions/core-api'

import {
  StyledEnterButton,
  StyledBadgeContainer,
  StyledBadgeStack,
  StyledCard,
  StyledCardContent,
  StyledCardContentStack,
  StyledCardMedia,
  StyledCardMediaContainer,
  StyledClosedEvent,
  StyledClosedEventBadge,
  StyledClosedEventContainer,
  StyledSoldOutBadge,
  StyledSoldOutTypography,
  StyledCompetitionNameTypography,
  StyledClosedEventTypography
} from './CompetitionCard.styled'

import { ICompetitionCardProps } from './CompetitionCard.types'

const CompetitionCard = (props: ICompetitionCardProps) => {
  const {
    competition,
    canShowName = true,
    isPast = false,
    analytics = null,
    layoutMode = 'fixed',
    showEndingSoonBadge = true
  } = props

  const origTicketPrice = useMemo(() => {
    const { ticketPrice } = competition

    return round2Decimal(ticketPrice)
  }, [competition])

  const saleTicketPrice = useMemo(() => {
    const { saleTicketPrice, ticketPrice } = competition

    return round2Decimal(saleTicketPrice || ticketPrice)
  }, [competition])

  const canShowCompetitionBadges = useMemo(() => {
    const { isSoldOut } = competition

    return !isPast && !isSoldOut
  }, [isPast, competition])

  const canShowEnterBtn = useMemo(() => {
    const { isSoldOut } = competition

    return !isPast && !isSoldOut
  }, [isPast, competition])

  const href = useMemo(() => {
    const { slugUrl } = competition

    return `/competitions/${isPast ? `past/${slugUrl}` : slugUrl}`
  }, [competition, isPast])

  /**
   * @link https://support.dataart.com/browse/ELITE-407
   */
  const renderCompetitionBadges = useCallback(
    (competition: CompetitionBaseDto) => {
      const { isFree, isAppOnly } = competition
      const isEndingSoon = isDrawToday(competition)

      if (isAppOnly && isFree && isEndingSoon) {
        return (
          <StyledBadgeStack layoutMode={layoutMode}>
            <CompetitionCardBadge
              layoutMode={layoutMode}
              variant="appExclusive"
              badgeContent="App exclusive"
            />

            <CompetitionCardBadge
              layoutMode={layoutMode}
              variant="freeToEnter"
              badgeContent="Free to enter"
            />
          </StyledBadgeStack>
        )
      }

      if (isFree && isEndingSoon) {
        return (
          <StyledBadgeStack layoutMode={layoutMode}>
            {showEndingSoonBadge && (
              <CompetitionCardBadge
                layoutMode={layoutMode}
                variant="endingSoon"
                badgeContent="Ending soon"
              />
            )}

            <CompetitionCardBadge
              layoutMode={layoutMode}
              variant="freeToEnter"
              badgeContent="Free to enter"
            />
          </StyledBadgeStack>
        )
      }

      if (isAppOnly && isFree) {
        return (
          <StyledBadgeStack layoutMode={layoutMode}>
            <CompetitionCardBadge
              layoutMode={layoutMode}
              variant="appExclusive"
              badgeContent="App exclusive"
            />

            <CompetitionCardBadge
              layoutMode={layoutMode}
              variant="freeToEnter"
              badgeContent="Free to enter"
            />
          </StyledBadgeStack>
        )
      }

      if (isFree) {
        return (
          <StyledBadgeStack layoutMode={layoutMode}>
            <CompetitionCardBadge
              layoutMode={layoutMode}
              variant="freeToEnter"
              badgeContent="Free to enter"
            />
          </StyledBadgeStack>
        )
      }

      if (isAppOnly) {
        return (
          <StyledBadgeStack layoutMode={layoutMode}>
            <CompetitionCardBadge
              layoutMode={layoutMode}
              variant="appExclusive"
              badgeContent="App exclusive"
            />

            {renderPriceBadge(competition)}
          </StyledBadgeStack>
        )
      }

      if (isEndingSoon && showEndingSoonBadge) {
        return (
          <StyledBadgeStack layoutMode={layoutMode}>
            <CompetitionCardBadge
              layoutMode={layoutMode}
              variant="endingSoon"
              badgeContent="Ending soon"
            />

            {renderPriceBadge(competition)}
          </StyledBadgeStack>
        )
      }

      return (
        <StyledBadgeStack layoutMode={layoutMode}>
          {renderPriceBadge(competition)}
        </StyledBadgeStack>
      )
    },
    [layoutMode]
  )

  const renderPriceBadge = useCallback(
    (competition: CompetitionBaseDto) => {
      const { ticketPrice, isOnSale } = competition

      if (isOnSale) {
        return (
          <DiscountPriceBadge
            layoutMode={layoutMode}
            originalPrice={origTicketPrice}
            discountPrice={saleTicketPrice}
          />
        )
      }

      return (
        <CompetitionCardBadge
          layoutMode={layoutMode}
          variant="price"
          badgeContent={`£${round2Decimal(ticketPrice)}`}
        />
      )
    },
    [layoutMode]
  )

  return (
    <Link href={href} prefetch={false}>
      <a>
        <StyledCard
          onClick={() => {
            if (!isNil(analytics)) {
              tracking.customEvent(analytics.eventName, analytics.payload)
            }
          }}
        >
          <StyledCardMediaContainer>
            <StyledCardMedia isPast={isPast}>
              <CdnImage
                alt={competition.name}
                image={competition.cdnImage}
                placeholder={competition.imagePlaceholder}
                desktop="medium"
                fallbackSrc={getLocalAssetFromCDN(
                  '/assets/img/competition-default-image.webp'
                )}
              />
            </StyledCardMedia>

            <StyledBadgeContainer layoutMode={layoutMode}>
              {canShowCompetitionBadges && renderCompetitionBadges(competition)}
            </StyledBadgeContainer>

            {isPast && (
              <StyledClosedEventContainer>
                <StyledClosedEvent>
                  <StyledClosedEventBadge>
                    <StyledClosedEventTypography
                      layoutMode={layoutMode}
                      fontFamily="Poppins"
                      fontWeight={400}
                      lineHeight={{
                        lg: '40px',
                        md: '32px',
                        sm: '32px',
                        xs: '24px'
                      }}
                      fontSize={{
                        lg: '48px',
                        md: '40px',
                        sm: '32px',
                        xs: '24px'
                      }}
                    >
                      Closed
                    </StyledClosedEventTypography>
                  </StyledClosedEventBadge>
                </StyledClosedEvent>
              </StyledClosedEventContainer>
            )}
          </StyledCardMediaContainer>

          <StyledCardContent layoutMode={layoutMode}>
            <StyledCardContentStack spacing={2} justifyContent="space-between">
              {canShowName && (
                <StyledCompetitionNameTypography
                  layoutMode={layoutMode}
                  fontFamily="Poppins"
                  fontWeight={500}
                  lineHeight={{
                    lg: '24px',
                    md: '22px',
                    sm: '20px',
                    xs: '18px'
                  }}
                  fontSize={{
                    lg: '18px',
                    md: '16px',
                    sm: '16px',
                    xs: '14px'
                  }}
                >
                  {competition.name}
                </StyledCompetitionNameTypography>
              )}

              {canShowEnterBtn && (
                <StyledEnterButton layoutMode={layoutMode} size="small">
                  Enter
                </StyledEnterButton>
              )}

              {!isPast && competition.isSoldOut && (
                <StyledSoldOutBadge layoutMode={layoutMode}>
                  <StyledSoldOutTypography
                    layoutMode={layoutMode}
                    fontFamily="Poppins"
                    fontWeight={500}
                    lineHeight={{
                      lg: '18px',
                      md: '18px',
                      sm: '16px',
                      xs: '16px'
                    }}
                    fontSize={{
                      lg: '14px',
                      md: '14px',
                      sm: '12px',
                      xs: '12px'
                    }}
                  >
                    Sold Out
                  </StyledSoldOutTypography>
                </StyledSoldOutBadge>
              )}
            </StyledCardContentStack>
          </StyledCardContent>
        </StyledCard>
      </a>
    </Link>
  )
}

export default CompetitionCard
