import { isNil } from 'lodash-es'

import { useEffect, useMemo, useState } from 'react'

import qs from 'query-string'

import axios, {
  type AxiosError,
  type AxiosResponse,
  HttpStatusCode
} from 'axios'

import { Settings } from 'luxon'

import { useRouter } from 'next/router'
import App, { type AppContext } from 'next/app'
import dynamic from 'next/dynamic'
import NextHead from 'next/head'

import nookies from 'nookies'

import { Provider } from 'mobx-react'

import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

import { dehydrate, Hydrate, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import {
  ApplicationBannersService,
  ApplicationConfigService,
  CompetitionCategoriesService,
  CompetitionsService,
  CompetitionStatus,
  OpenAPI
} from '@elitecompetitions/core-api'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-modal-video/css/modal-video.min.css'

import '../../public/assets/css/bootstrap.min.css'
import '../../public/assets/css/style.css'
import '../../public/assets/css/responsive.css'

import { isBrowser } from '@helpers/isBrowser'

import initializeStore from '@store'

import {
  getHydrateState,
  getQueryClient,
  queryClient,
  type ResultAppProps
} from '@services/queryClient'
import config from '@services/config'
import { requestV2Interceptor } from '@services/api'

import { API_TIMEOUT_MS } from '@helpers/constants'

import { Typography } from '@components/ui/atoms'
import { Loader } from '@components/ui/molecules'
import DeferredComponents from '@components/DeferredComponents'

import { QueryClientKeysEnum } from '@enums'

import { getAppPlatform } from '@utils'
import { RiskJs } from '@utils/RiskJs/RiskJs'

import theme from '@theme'

import '@styles/globals.css'

import { authStoreFactory } from '@store/Auth'

const LazyComponents = dynamic(() => import('@components/LazyComponents'), {
  ssr: false
})

const Footer = dynamic(() => import('@components/ui/organisms/Footer'), {
  ssr: true
})

const Navbar = dynamic(() => import('@components/ui/organisms/Navbar'), {
  ssr: true
})

Settings.defaultLocale = 'en-GB'

OpenAPI.BASE = config.coreApiUrl
OpenAPI.HEADERS = async () => ({
  platform: getAppPlatform(),
  'x-refresh-token': await authStoreFactory().getRefreshToken()
})
OpenAPI.TOKEN = () => authStoreFactory().getToken()
OpenAPI.interceptors.request.use(requestV2Interceptor)
OpenAPI.interceptors.response.use(async (response: AxiosResponse) => {
  if (!isNil(response)) {
    const { status = null } = response

    if (
      isBrowser() &&
      (status === HttpStatusCode.Forbidden ||
        status === HttpStatusCode.Unauthorized)
    ) {
      await authStoreFactory().logout()

      window.location.replace(`/login?referrer=${window.location.pathname}`)
    }
  }

  return response
})

axios.interceptors.request.use(requestV2Interceptor)
axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    if (!isNil(error)) {
      const { response = null } = error

      if (!isNil(response)) {
        const { status = null } = response

        if (
          isBrowser() &&
          (status === HttpStatusCode.Forbidden ||
            status === HttpStatusCode.Unauthorized)
        ) {
          await authStoreFactory().logout()

          window.location.replace(`/login?referrer=${window.location.pathname}`)
        }
      }
    }

    return error
  }
)
axios.defaults.validateStatus = (status: HttpStatusCode) => {
  return status < HttpStatusCode.InternalServerError
}
axios.defaults.timeout = API_TIMEOUT_MS

const EliteApp = (props: ResultAppProps) => {
  const { Component, pageProps, userAgent, initialMobxStore } = props

  const [isRouteChanging, setIsRouteChanging] = useState(false)

  const router = useRouter()

  const mobxStore = useMemo(() => {
    return !isBrowser() ? initialMobxStore : initializeStore(initialMobxStore)
  }, [initialMobxStore])

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      if (isBrowser()) {
        setTimeout(() => {
          window.scrollTo(0, 0)
        }, 0)
      }

      setIsRouteChanging(false)
    })

    router.events.on('routeChangeStart', () => {
      setIsRouteChanging(true)
    })

    router.events.on('routeChangeError', () => {
      setIsRouteChanging(false)
    })

    /**
     * @link https://developer.chrome.com/blog/history-api-scroll-restoration
     */
    if (isBrowser() && 'scrollRestoration' in history) {
      history.scrollRestoration = 'manual'
    }

    if (isBrowser() && window.sessionStorage) {
      window.sessionStorage.setItem(
        'initial_query_params',
        qs.stringify(router.query)
      )
    }
  }, [router])

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={getHydrateState(props)}>
            <Provider {...mobxStore} userAgent={userAgent}>
              <NextHead>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, maximum-scale=1"
                />
              </NextHead>

              <RiskJs />

              <Navbar />

              <main
                style={{
                  minHeight: '75vh'
                }}
              >
                <Component {...pageProps} userAgent={userAgent} />
              </main>

              <div id="modal-root" />

              <Footer />

              <DeferredComponents Component={<LazyComponents />} />

              {isRouteChanging && (
                <Loader isOpen={true}>
                  <Typography
                    fontVariant="heading-3"
                    weight="semibold"
                    textAlign="center"
                    color="var(--Neutral1)"
                  >
                    Loading...
                  </Typography>
                </Loader>
              )}

              <ReactQueryDevtools initialIsOpen={false} />
            </Provider>
          </Hydrate>
        </QueryClientProvider>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

EliteApp.getInitialProps = async (appContext: AppContext) => {
  const req = appContext.ctx.req
  const mobxStore = initializeStore()

  const queryClient = getQueryClient()

  if (appContext.ctx.req) {
    const { token, refreshToken } = nookies.get(appContext.ctx)

    if (token) {
      try {
        const { data: user } = await axios.get(
          `${config.coreApiUrl}/v1/users/me`,
          {
            headers: {
              'x-refresh-token': refreshToken,
              Authorization: `Bearer ${token}`
            }
          }
        )

        mobxStore.authStore.profile = user
        mobxStore.authStore.setToken(token)
        mobxStore.authStore.setRefreshToken(refreshToken)
        mobxStore.authStore.setWalletAmountLoading(true)
      } catch (error) {
        OpenAPI.TOKEN = undefined
      }
    }
  }

  const categoriesParams = {
    take: -1,
    filterIsPrimary: true
  }

  const competitionsParams = {
    filterStatus: CompetitionStatus.ACTIVE,
    filterIsInvisible: false,
    take: -1
  }

  const [appProps] = await Promise.all([
    App.getInitialProps(appContext),
    queryClient.prefetchQuery({
      queryKey: [QueryClientKeysEnum.GET_APPLICATION_CONFIG],
      queryFn: () =>
        ApplicationConfigService.getApplicationConfig({
          tenantId: 'default'
        })
    }),
    queryClient.prefetchQuery({
      queryKey: [QueryClientKeysEnum.GET_PROMOTION_BANNER_TEXT],
      queryFn: () => ApplicationBannersService.getApplicationBanner()
    }),
    queryClient.prefetchQuery({
      queryKey: [QueryClientKeysEnum.GET_CATEGORIES, categoriesParams],
      queryFn: () =>
        CompetitionCategoriesService.getCompetitionCategories(categoriesParams)
    }),
    queryClient.prefetchQuery({
      queryKey: [QueryClientKeysEnum.GET_COMPETITIONS, competitionsParams],
      queryFn: () => CompetitionsService.getCompetitions(competitionsParams)
    })
  ])

  const clientTelemetryData = {
    clientGeoLocation:
      req && req.headers['x-client-geo-location']
        ? req.headers['x-client-geo-location']
        : null,
    clientIpAddress:
      req && req.headers['x-client-ip'] ? req.headers['x-client-ip'] : null,
    clientLatLong:
      req && req.headers['x-client-lat-long']
        ? req.headers['x-client-lat-long']
        : null
  }

  const res = appContext.ctx.res

  if (res && !res.headersSent) {
    res.setHeader(
      'Set-Cookie',
      `clientTelemetryCookieData=${JSON.stringify(
        clientTelemetryData
      )};max-age=${6 * 60 * 60}`
    )
  }

  return {
    ...appProps,
    userAgent: req ? req.headers['user-agent'] : navigator.userAgent,
    initialMobxStore: mobxStore,
    dehydratedState: dehydrate(queryClient)
  }
}

export default EliteApp

export function reportWebVitals(metric) {
  switch (metric.name) {
    case 'TTFB':
      console.log('TTFB', metric)
      break
    default:
      break
  }
}
