import React from 'react'

import getYouTubeID from 'get-youtube-id'

import { v4 as uuid } from 'uuid'

import { MobXProviderContext } from 'mobx-react'

import { isBrowser } from '@helpers/isBrowser'

import * as Sentry from '@sentry/nextjs'

import { authStoreFactory } from '@store/Auth'
import { ComposedStore } from '@store'

export const getYouTubeThumbnail = (url: string) => {
  let id = getYouTubeID(url)

  if (!id && url.length === 11) {
    id = url
  }

  return {
    default: {
      url: 'https://img.youtube.com/vi/' + id + '/default.jpg',
      width: 120,
      height: 90
    },
    medium: {
      url: 'https://img.youtube.com/vi/' + id + '/mqdefault.jpg',
      width: 320,
      height: 180
    },
    high: {
      url: 'https://img.youtube.com/vi/' + id + '/hqdefault.jpg',
      width: 480,
      height: 360
    }
  }
}

export const convertToMinFromSec = seconds => {
  const date = new Date(null)
  date.setSeconds(seconds)
  const result = date.toISOString().substr(14, 5)

  return result
}

export const roundNumber = (number, precision) => {
  return Number(Math.round(number + 'e2') + 'e-2').toFixed(precision)
}

export const round2Decimal = (number: number) => {
  return roundNumber(number, 2)
}

const truncate = (number, precision) => {
  return number.toFixed(++precision).slice(0, -1)
}

export const calculateWalletAmount = (cartAmount, walletAmountPercentage) => {
  const walletAmount = (cartAmount / 100) * walletAmountPercentage
  return walletAmount === 0 ? 0 : parseFloat(truncate(walletAmount, 2))
}

export const useStores = () => {
  return React.useContext(MobXProviderContext) as ComposedStore
}

export const getWeeksInMonth = (currentYear, currentMonth) => {
  return Math.ceil(getDaysInMonth(currentMonth, currentYear) / 7)
}

export const getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate()
}

export const safeParsePhoneNumber = (phoneNumber = ''.toString()) => {
  if (!phoneNumber || phoneNumber === '') {
    return phoneNumber
  }

  if (phoneNumber.startsWith('0')) {
    return phoneNumber.replace(/0/, '+44')
  } else if (!phoneNumber.startsWith('44') && !phoneNumber.startsWith('+44')) {
    if (phoneNumber.startsWith('+')) {
      return phoneNumber
    }

    return '+44' + phoneNumber
  }

  return phoneNumber.replace(/^(\+?440)|^(\+?44)/, '+44')
}

export const getTimeSince = date => {
  let seconds = Math.floor((new Date() - new Date(date)) / 1000)

  let interval = seconds / 86400

  if (interval > 1) {
    return new Date(date).toLocaleDateString()
  }

  interval = seconds / 3600

  if (interval > 1) {
    const time = Math.floor(interval)

    return time + (time === 1 ? ' hour' : ' hours')
  }

  interval = seconds / 60

  if (interval > 1) {
    const time = Math.floor(interval)

    return time + (time === 1 ? ' min' : ' mins')
  }

  if (seconds <= 0) {
    seconds = 1
  }

  return Math.floor(seconds) + ' secs'
}

// region Flutter WebView

export const getAppPlatform = () => {
  if (isBrowser()) {
    return (localStorage.getItem('nativeApp') || 'WEB').toUpperCase() as
      | 'WEB'
      | 'ANDROID'
      | 'IOS'
  }

  return 'WEB'
}

export const isIOS = () => {
  const platform = getAppPlatform()

  return platform === 'IOS'
}

export const isANDROID = () => {
  const platform = getAppPlatform()

  return platform === 'ANDROID'
}

export const isWEB = () => {
  const platform = getAppPlatform()

  return platform === 'WEB'
}

export const isMobileApp = () => {
  const platform = getAppPlatform()

  return platform === 'IOS' || platform === 'ANDROID'
}

// endregion

export const isIos = () => {
  if (!isBrowser()) {
    return false
  }

  const platform = navigator?.userAgentData?.platform

  return (
    platform === 'iOS' ||
    (navigator?.platform === 'MacIntel' && navigator?.maxTouchPoints > 1) ||
    /iPad|iPhone|iPod/.test(navigator?.platform)
  )
}

export const isAndroid = () => {
  if (!isBrowser()) {
    return false
  }

  const platform = navigator?.userAgentData?.platform

  return (
    platform === 'Android' ||
    navigator?.platform === 'Android' ||
    navigator?.userAgent?.includes(' Android ') === true ||
    process?.platform === 'android'
  )
}

export function getCookies(): { [key: string]: string } {
  try {
    if (typeof window !== 'undefined') {
      return document.cookie
        .split(';')
        .map(v => v.split('='))
        .reduce((acc, v) => {
          acc[decodeURIComponent((v[0] || '').trim())] = decodeURIComponent(
            (v[1] || '').trim()
          )
          return acc
        }, {})
    }
    return {}
  } catch (err) {
    console.error('Error -> getCookies', err)
    return {}
  }
}

export const klaviyo_added_to_cart_bulk = (tickets: string[], competition) => {
  try {
    if (typeof window !== 'undefined' && window.klaviyo) {
      const platform = getAppPlatform()
      const { profile } = authStoreFactory()
      if (!profile) {
        return
      }
      const payload = {
        ticketNumbers: tickets,
        competitionName: competition.competitionName,
        competitionImage: competition.competitionImage,
        competitionKey: competition.primaryKey,
        competitionUrl:
          'https://' +
          window.location.hostname +
          '/competitions/' +
          competition.slugUrl,
        quantity: tickets.length,
        $value:
          (competition.isOnSale
            ? competition.saleTicketPrice
            : competition.ticketPrice) * tickets.length,
        $event_id: uuid(),
        platform
      }
      if (competition.categories) {
        const categories = competition.categories.map(category => category.name)
        payload.categories = categories
      }
      window.klaviyo.identify(
        {
          $email: profile.email
        },
        () => {
          window.klaviyo.track('Added to Cart', payload, () =>
            console.log('track event sent')
          )
        }
      )
    }
  } catch (err) {
    Sentry.captureException(err)
  }
}
