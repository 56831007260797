import { CdnImage } from '@elitecompetitions/core-api'

import config from '@services/config'

const DEFAULT_CDN_DOMAIN = config.cdn.images

export const replaceImagePathTemplate = (
  image: CdnImage,
  size: string,
  extension: string,
  version: number,
  cdnDomain: string = DEFAULT_CDN_DOMAIN
) => {
  return `${cdnDomain}/${image.pathTemplate
    .replace('[size]', size)
    .replace('[extension]', extension)
    .replace('[version]', `x${version}`)}`
}
