import Image from 'next/image'

import { useMemo } from 'react'

import Link from 'next/link'

import { useRouter } from 'next/router'

import { Carousel } from 'react-responsive-carousel'

import Section from '@base-components/Section'

import { Loader } from '@components/ui/molecules'
import { Typography } from '@components/ui/atoms'

import { replaceImagePathTemplate } from '@helpers/competitions/replace-image-path-template'

import { type IAllTimeWinnersProps } from './AllTimeWinners.types'

const AllTimeWinners = (props: IAllTimeWinnersProps) => {
  const { values = [], loadingStatus, children } = props

  const router = useRouter()

  const carouselPics = useMemo(() => {
    return values.map((value, index) => {
      const { winnerName, prizeName, drawTime, winnerProfileImage } = value

      return (
        <Link
          key={`all-time-winner-${index}`}
          href="/winners-podium"
          prefetch={false}
        >
          <a className="ptr">
            <Image
              loading="lazy"
              alt={`${winnerName}-${prizeName}`}
              src={replaceImagePathTemplate(
                winnerProfileImage,
                'medium',
                'webp',
                2
              )}
              width={600}
              height={350}
            />

            {prizeName && (
              <h4 className="px-4 mt-4 mb-2">
                <span>{prizeName}</span>
              </h4>
            )}

            {winnerName && <p>{winnerName}</p>}

            {drawTime && <p>{drawTime}</p>}
          </a>
        </Link>
      )
    })
  }, [values])

  return (
    <Section className="container">
      {children}

      {values.length > 0 && !loadingStatus ? (
        <div className="row justify-content-md-center pb-5 all-time-winners">
          <div className="col col-md-7 ptr">
            <Carousel
              autoPlay={true}
              showArrows={true}
              emulateTouch={true}
              infiniteLoop={true}
              interval={5000}
              showThumbs={false}
              stopOnHover={true}
              showIndicators={true}
              showStatus={false}
              onClickItem={async () => {
                await router.push('/winners-podium')
              }}
            >
              {carouselPics}
            </Carousel>
          </div>
        </div>
      ) : !loadingStatus ? (
        <div className="row justify-content-left" key="winners-loading">
          <div className="col-lg-12">No Winners to show at the moment!</div>
        </div>
      ) : (
        <Loader isOpen={true}>
          <Typography
            fontVariant="heading-3"
            weight="semibold"
            textAlign="center"
            color="var(--Neutral1)"
          >
            Loading...
          </Typography>
        </Loader>
      )}
    </Section>
  )
}

export default AllTimeWinners
