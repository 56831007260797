import { css, styled, Typography } from '@mui/material'

import { ITypographyProps } from './Typography.types'

export const StyledTypography = styled(Typography, {
  shouldForwardProp: propName =>
    propName !== 'fontVariant' &&
    propName !== 'weight' &&
    propName !== 'textDecoration'
})<ITypographyProps>`
  ${({ textDecoration = ['none'] }) => {
    return css`
      text-decoration: ${textDecoration.join(' ')};
    `
  }}

  ${({ weight = 'regular' }) => {
    if (weight === 'bold') {
      return css`
        font-weight: 700;
      `
    }

    if (weight === 'semibold') {
      return css`
        font-weight: 600;
      `
    }

    if (weight === 'medium') {
      return css`
        font-weight: 500;
      `
    }

    if (weight === 'regular') {
      return css`
        font-weight: 400;
      `
    }
  }}

  ${({ fontVariant }) => {
    if (fontVariant === 'heading-1') {
      return css`
        // Mobile
        @media (max-width: 899px) {
          font-size: 30px;
          line-height: 38px;
        }

        // Tablet
        @media (min-width: 900px) and (max-width: 1239px) {
          font-size: 30px;
          line-height: 38px;
        }

        // Desktop
        @media (min-width: 1240px) {
          font-size: 40px;
          line-height: 48px;
        }
      `
    }

    if (fontVariant === 'heading-2') {
      return css`
        // Mobile
        @media (max-width: 899px) {
          font-size: 24px;
          line-height: 32px;
        }

        // Tablet
        @media (min-width: 900px) and (max-width: 1239px) {
          font-size: 26px;
          line-height: 34px;
        }

        // Desktop
        @media (min-width: 1240px) {
          font-size: 28px;
          line-height: 36px;
        }
      `
    }

    if (fontVariant === 'heading-3') {
      return css`
        // Mobile
        @media (max-width: 899px) {
          font-size: 20px;
          line-height: 26px;
        }

        // Tablet
        @media (min-width: 900px) and (max-width: 1239px) {
          font-size: 24px;
          line-height: 32px;
        }

        // Desktop
        @media (min-width: 1240px) {
          font-size: 24px;
          line-height: 32px;
        }
      `
    }

    if (fontVariant === 'heading-4') {
      return css`
        // Mobile
        @media (max-width: 899px) {
          font-size: 17px;
          line-height: 24px;
        }

        // Tablet
        @media (min-width: 900px) and (max-width: 1239px) {
          font-size: 18px;
          line-height: 24px;
        }

        // Desktop
        @media (min-width: 1240px) {
          font-size: 20px;
          line-height: 26px;
        }
      `
    }

    if (fontVariant === 'body-1') {
      // Desktop / Tablet / Mobile
      return css`
        font-size: 16px;
        line-height: 22px;
      `
    }

    if (fontVariant === 'body-2') {
      // Desktop / Tablet / Mobile
      return css`
        font-size: 14px;
        line-height: 18px;
      `
    }

    if (fontVariant === 'body-3') {
      // Desktop / Tablet / Mobile
      return css`
        font-size: 12px;
        line-height: 16px;
      `
    }

    if (fontVariant === 'body-4') {
      // Desktop / Tablet / Mobile
      return css`
        font-size: 10px;
        line-height: 14px;
      `
    }
  }}
`
