import { DialogActions, DialogContent, styled } from '@mui/material'

import { Button, Typography } from '@components/ui/atoms'

export const StyledBackButton = styled(Button)`
  margin-right: 8px;
`

export const StyledDialogContent = styled(DialogContent)`
  padding: 0;
`

export const StyledSectionButtons = styled('div')`
  display: flex;
  justify-content: center;
`

export const StyledDialogActions = styled(DialogActions)`
  display: block !important;
`

export const StyledDisclaimerNote = styled(Typography)`
  color: var(--Primary);
`

export const StyledCheckboxLabel = styled(Typography)`
  margin-top: 8px;
  margin-bottom: 10px;
`
