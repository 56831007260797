import { MuiTelInput } from 'mui-tel-input'

import { css, styled } from '@mui/material'

import { TelInputProps } from './TelInput.types'

export const StyledMuiTelInput = styled(MuiTelInput, {
  shouldForwardProp: propName => propName !== 'mode' && propName !== 'isError'
})<TelInputProps>`
  & .MuiInputBase-root {
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    max-height: 40px;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    padding-left: 0;

    fieldset {
      border: none;
    }

    input {
      padding: 10px 16px;
      border-radius: 8px;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;

      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        transition: background-color 0s 600000s, color 0s 600000s !important;
      }

      ${({ mode = 'light', isError = false }) => {
        if (isError) {
          if (mode === 'light') {
            return css`
              color: var(--PrimartVariant);
              background-color: rgba(192, 1, 0, 0.2);

              &:focus {
                border-color: var(--Primary);
              }
            `
          }

          if (mode === 'dark') {
            return css`
              color: var(--Neutral4);
              background-color: rgba(192, 1, 0, 0.2);

              &:focus {
                border-color: var(--Primary);
              }
            `
          }
        }

        if (mode === 'dark') {
          return css`
            color: var(--Neutral1);
            background-color: var(--Neutral7);

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                background-color: var(--Neutral6);
              }
            }

            &:focus {
              background-color: var(--Neutral8);
              border-color: var(--Neutral5);
            }
          `
        }

        if (mode === 'light') {
          return css`
            color: var(--Neutral7);
            background-color: var(--Neutral2);

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                background-color: rgba(56, 114, 255, 0.2);
              }
            }

            &:focus {
              border-color: var(--Secondary);
            }
          `
        }
      }}
    }
  }
`
