import { StyledTypography } from './Typography.styled'
import { ITypographyProps } from './Typography.types'

const Typography = (props: ITypographyProps) => {
  const {
    fontFamily = 'Poppins',
    fontVariant = 'body-1',
    weight = 'regular',
    textDecoration = ['none'],
    children,
    ...otherProps
  } = props

  return (
    <StyledTypography
      textDecoration={textDecoration}
      fontFamily={fontFamily}
      fontVariant={fontVariant}
      weight={weight}
      {...otherProps}
    >
      {children}
    </StyledTypography>
  )
}

export default Typography
